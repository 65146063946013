import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ImageService {

    constructor() {
    }

    getImages(): string[] {
        return [
            "IMG_2089.JPG", "IMG_0049.jpg", "IMG_2098-Kopie.JPG",
            "IMG_2157-Kopie.JPG", "IMG_1996.JPG", "IMG_2028.JPG",
            "IMG_2479-Kopie.JPG", "IMG_2205fff.jpg", "IMG_2316.JPG", "IMG_2353.JPG",
            "IMG_2387.JPG", "IMG_2390.JPG", "IMG_2433.JPG", "IMG_2435.JPG",
            "IMG_2430.JPG", "IMG_2420.JPG", "IMG_2452.JPG", "IMG_2448.JPG",
            "IMG_2490.JPG", "IMG-20210906-WA0025.jpg", "IMG-20210908-WA0003.jpg",
            "IMG-20210908-WA0006.jpg", "IMG_2524.JPG", "IMG_2541.JPG", "IMG_2546.JPG", "IMG_2558.JPG",
            "IMG_3782.JPG", "IMG-20220507-WA0007.jpg",
			"IMG_3793.JPG", "IMG_3894.JPG", "IMG_3910.JPG", "IMG_3915.JPG", "IMG_3939.JPG",
			"IMG_3958.JPG", "IMG_3967.JPG", "IMG_3540.JPG", "2022_0613_14124900", "2022_0613_14125900", "IMG_3987.JPG", "IMG_3990.JPG", "IMG_3989.JPG", "IMG-20220620-WA0001.jpg", "20220619_175444.jpg", "IMG-20220620-WA0016.jpg", "IMG-20220813-WA0003.jpg", "IMG-20221005-WA0013.jpg", "IMG_4055.JPG", "IMG_4051.JPG", "IMG_0022.JPG", "IMG_0036.JPG", "IMG_4071.JPG", "IMG_4084.JPG", "HerrPhilippi.jpg", "20221009_140710.jpg", "IMG-20221009-WA0002.jpg", "IMG-20221204-WA0002.jpg", "IMG-20221204-WA0015.jpg", "IMG-20221220-WA0002.jpg", "IMG_4104-Kopie.JPG", "IMG_4109-Kopie.JPG", "20221223_101024.jpg", "IMG_4125.JPG", "IMG_4166.JPG", "IMG_4253.JPG", "IMG_4257.JPG", "IMG_3058.jpg", "IMG_4292.JPG", "IMG_4308.JPG", "IMG_4313.JPG", "IMG_4314.JPG", "IMG_4319.JPG", "IMG_4324.JPG", "IMG-20230609-WA0013.jpg", "IMG_0218.JPG", "IMG_0232.JPG", "IMG_4332.JPG", "IMG_4335.JPG", "IMG_4343.JPG", "12345.jpg", "20230705_162129.jpg", "20230705_162154.jpg", "20230714gochersommer.jpg", "IMG-20230806-WA0011.jpg", "IMG_8481.jpg", "IMG-20231001-WA0002.jpg", "DSCF2922.jpg", "IMG_4413.JPG" ];
    }

}
